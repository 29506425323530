<template>

<div class="news">

	<div class="news-title">Latest News</div>

	<div class="news-item" v-for="(link, index) in links" :key="index">

		<a v-if="isExternal(link.url)" :href="link.url" target="_blank" class="news-item-title">{{ link.name }}</a>
		<router-link v-if="!isExternal(link.url)" :to="$link(link.url)" class="news-item-title">{{ link.name }}</router-link>
		<div class="news-item-date">{{ link.date | formatDate('MMMM Do, YYYY') }}</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['links'],

	methods: {

		isExternal: function(url) {

			return url.indexOf('http') === 0

		}

	}

}

</script>

<style scoped>

.news {
	margin-top: 40px;
	padding-bottom: 20px;
}

.news-title {
	font-weight: bold;
	font-size: 24px;
	padding-bottom: 10px;
	border-bottom: 1px solid #ddd;
	color: #1A234C;
}

.news-item {
	padding: 10px 0px;
	border-bottom: 1px solid #ddd;
}

.news-item-title {
	color: #1A234C;
	font-size: 16px;
	font-weight: bold;
	line-height: 22px;
	margin-bottom: 10px;
	display: block;
}

.news-item-title:hover {
	text-decoration: underline;
}

.news-item-date {
	color: #1277D4;
	font-size: 14px;
}

</style>
